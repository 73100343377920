import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const batchSlice = createSlice({
    name: 'order',
    initialState: {
        selectedBatchData: null
    },
    reducers: {
        handleSetSelectedBatch: (state, action) => {
            state.selectedBatchData = action.payload
        }
    }
})

export const {
    handleSetSelectedBatch
} = batchSlice.actions

export const searchBatchByOrderNumber = async (orderNumber) => {
    try {
        const response = await axios.get(`${BASE_URL}itemBatch?orderNumber=${orderNumber}&active=true`);
        return response.data
    } catch ({err, response}) {
        throw (err, response);
    }
};

export const fetchProductListByOrderNumber = async (sendParams) => {
    try {
        const response = await axios.get(`${BASE_URL}product`, {params: sendParams});
        return response.data
    } catch ({err, response}) {
        throw (err, response);
    }
};

export const deleteProduct = async (id) => {
    try {
        const response = await axios.delete(`${BASE_URL}product?id=${id}`);
        return response.data
    } catch ({err, response}) {
        throw (err, response);
    }
};

export const saveProduct = async (data) => {
    try {
        const response = await axios.get(`${BASE_URL}product?id=${id}`);
        return response.data
    } catch ({err, response}) {
        throw (err, response);
    }
};

export const createProduct = async (data) => {
    try {
        const response = await axios.post(`${BASE_URL}product`, data);
        return response.data
    } catch ({err, response}) {
        throw (err, response);
    }
};

export const fetchBatchDataByBatchId = async (batchId) => {
    try {
        const response = await axios.get(`${BASE_URL}itemBatch/findBy/id?id=${batchId}`);
        return response.data
    } catch ({err, response}) {
        throw (err, response);
    }
};

export const batchQRPrint = async (data) => {
    try {
        const response = await axios.post(`${BASE_URL}itemBatch/printQR`, data);
        return response.data
    } catch ({err, response}) {
        throw (err, response);
    }
};

export const productQRPrint = async (data) => {
    try {
        const response = await axios.post(`${BASE_URL}product/printQr?productId=${data.id}&qty=${data.qty}`);
        return response.data
    } catch ({err, response}) {
        throw (err, response);
    }
};

export const fetchInvoice = async (orderId) => {
    try {
        const response = await axios.get(`${BASE_URL}invoice/findInvoice/byOrderId?orderId=${orderId}`);
        return response.data
    } catch ({err, response}) {
        throw (err, response);
    }
};


export default batchSlice.reducer