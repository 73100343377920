// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import dashboard from "./dashboard";
import city from "./city";
import batch from "./batch";
import configs from "./configs";


const rootReducer = {
  auth,
  navbar,
  layout,
  dashboard,
  city,
  batch,
  configs
}

export default rootReducer
