// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import {getAbilityList, getActionPermissionList} from "../services/permissionHelper";

// ** Set base url for the api

const BASE_URL = process.env.REACT_APP_API_BASE_URL
// const BASE_URL = process.env.REACT_APP_API_LOCAL_BASE_URL
const config = useJwt.jwtConfig

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  if (item !== "undefined") {
    return item ? JSON.parse(item) : {}
  } else {
    localStorage.clear()
    window.location.href = "/login"
  }
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleLogin: (state, action) => {
      const parsedToken = parseJwt(action.payload.token)
      state.userData = action.payload
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageCookieValueKeyName] = action.payload[config.storageCookieValueKeyName]
      // state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload))
      localStorage.setItem('exp', parsedToken.exp)
      localStorage.setItem('iat', parsedToken.iat)
      localStorage.setItem('email', parsedToken.sub)
      localStorage.setItem('firstName', parsedToken.firstName)
      localStorage.setItem('lastName', parsedToken.lastName)

      /**
       * @ROWEL
       * Set Ability List
       * */
      if (action && action.payload) {
        localStorage.setItem('permission', JSON.stringify(getAbilityList(action.payload)))
        localStorage.setItem('action_permission', JSON.stringify(getActionPermissionList(action.payload)))
      }

      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.token))
      localStorage.setItem(config.storageCookieValueKeyName, JSON.stringify(action.payload.cookieValue))
    },
    handleLogout: state => {
      state.userData = {}
      state[config.storageTokenKeyName] = null
      // state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.clear()
      window.location.replace('/login')
      // localStorage.removeItem(config.storageRefreshTokenKeyName)
    }
  }
})

export const { handleLogin, handleLogout } = authSlice.actions
export default authSlice.reducer