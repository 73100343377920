import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const configsSlice = createSlice({
    name: 'configs',
    initialState: {
    },
    reducers: {
    }
})

export const {  } = configsSlice.actions


export const addAlert = async (data) => {
    try {
        const response = await axios.post(`${BASE_URL}messaging/notification/toAllFarmer/weather`, data);
        return response.data
    } catch ({err, response}) {
        throw (err, response);
    }
};


export const fetchAlertListMutate = async (data) => {
    try {
        const response = await axios.get(`${BASE_URL}messaging`, {params: data});
        return response?.data
    } catch (err) {
        // throw new Error(err);
    }
};

export default configsSlice.reducer