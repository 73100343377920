import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const citySlice = createSlice({
    name: 'city',
    initialState: {
    },
    reducers: {
    }
})

export const {  } = citySlice.actions

export const fetchCityList = async () => {
    try {
        // const response = await axios.get(`${BASE_URL}city`);
        // return response.data
    } catch (err) {
        throw new Error(err);
    }
};

export default citySlice.reducer