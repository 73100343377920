import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const dashboardSlice = createSlice({
    name: 'dashboardData',
    initialState: {
    },
    reducers: {
    }
})

export const {  } = dashboardSlice.actions

export const fetchTotalFamerCount = async () => {
    try {
        const response = await axios.get(`${BASE_URL}dashboard/farmer/count?active=true`);
        return response.data
    } catch (err) {
        throw new Error(err);
    }
};

export const fetchTotalLandCount = async () => {
    try {
        const response = await axios.get(`${BASE_URL}dashboard/farmer/count/contract?active=true&contract=true`);
        return response.data
    } catch (err) {
        throw new Error(err);
    }
};

export const completedOrdersCount = async () => {
    try {
        const response = await axios.get(`${BASE_URL}dashboard/order/count/success`);
        return response.data
    } catch (err) {
        throw new Error(err);
    }
};

export const fetchOngoingFarmingCount = async () => {
    try {
        const response = await axios.get(`${BASE_URL}dashboard/farming/ongoing`);
        return response.data
    } catch (err) {
        throw new Error(err);
    }
};

export default dashboardSlice.reducer