import React, { Fragment } from "react";
// ** Custom Components
import Avatar from '@components/avatar'

const ToastContent = ({ title, color, icon, content }) => {
    return (
        <Fragment>
            <div className='toastify-header'>
                <div className='title-wrapper'>
                    <Avatar size='sm' color={color} icon={icon} />
                    <h6 className='toast-title fw-bold'>{title}</h6>
                </div>
            </div>
            <div className='toastify-body'>
                <span>{content}</span>
            </div>
        </Fragment>
    )
}

export default ToastContent