import axios from 'axios'
import { toast } from 'react-toastify'
import { AlertTriangle } from 'react-feather'
import ToastContent from '../../../components/ToastContent'
import jwtDefaultConfig from './jwtDefaultConfig'
import 'react-toastify/dist/ReactToastify.css';
import { handleLogout } from '@store/authentication'
import {useDispatch} from "react-redux";

export default class JwtService {
    // ** jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    // ** For Refreshing Token
    isAlreadyFetchingAccessToken = false

    // ** For Refreshing Token
    subscribers = []

    constructor(jwtOverrideConfig) {
        this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

        // ** Request Interceptor
        axios.interceptors.request.use(
            config => {
                // ** Get token from localStorage
                const accessToken = this.getToken()
                // ** If token is present add it to request's Authorization Header
                if (accessToken) {
                    // ** eslint-disable-next-line no-param-reassign
                    config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken.replace(/["]+/g, '')}`
                }
                return config
            },
            error => {
                return Promise.reject(error)
            }
        )

        // ** Add request/response interceptor
        axios.interceptors.response.use(
            response => {
                if (response.data.status === 403) {
                    toast.error(<ToastContent title="Authentication Failed" color="danger"
                        icon={<AlertTriangle size={16} />}
                        content="Check your email or password and try again!" />,
                        { icon: false, hideProgressBar: true, autoClose: 2000 })
                } else if (response.data.status === 423) {
                    toast.warning(
                        <ToastContent title="User Locked" color="warning" icon={<AlertTriangle size={16} />}
                            content="Your account is locked, contact an admin!" />,
                        { icon: false, hideProgressBar: true, autoClose: 2000 }
                    )
                }
                return response
            },
            error => {
                // ** const { config, response: { status } } = error
                const { config, response } = error
                const originalRequest = config
                // ** if (status === 401) {

                if (response && response.status === 401) {
                    // toast.error(
                    //     <ToastContent title="Error" color="danger" icon={<AlertTriangle size={16} />} content="Session Expired, Please login again!" />,
                    //     { icon: false, hideProgressBar: true, autoClose: 2000 }
                    // )
                    // localStorage.clear()
                    // window.location.href = "/login"
                    if (!this.isAlreadyFetchingAccessToken) {
                        this.isAlreadyFetchingAccessToken = true
                        const data = {
                            userName: localStorage.getItem('email'),
                            expireToken: this.getToken().replace(/["]/g, ""),
                            cookieValue: this.getCookieValue().replace(/["]/g, ""),
                            isFarmer: false
                        }
                        //console.log('DATA', data)
                        //console.log('this.getToken()', this.getToken())
                        //console.log('this.getToken().replace', this.getToken().replace(/["]/g, ""))

                        //console.log('this.getCookieValue()', this.getCookieValue())
                        //console.log('this.getCookieValue().replace', this.getCookieValue().replace(/["]/g, ""))

                        this.refreshToken(data).then(r => {
                            //console.log('refreshToken res', r)
                            if (r) {
                                if (!(r.data.status === 406) && !(r.data.message.includes("Already"))) {

                                    this.isAlreadyFetchingAccessToken = false

                                    // ** Update accessToken in localStorage
                                    // console.log('r.data.object.token', r.data.object.token);
                                    this.setUserData(JSON.stringify(r.data.object))
                                    this.setToken(JSON.stringify(r.data.object.token))
                                    // uncomment
                                    this.setRefreshToken(r.data.refreshToken)
                                    //
                                    //console.log('r.data.object.cookieValue', r.data.object.cookieValue);
                                    this.setCookieValueName(JSON.stringify(r.data.object.cookieValue))

                                    this.onAccessTokenFetched(JSON.stringify(r.data.object.token))
                                } else {
                                    localStorage.clear()
                                    window.location.href = "/login"
                                }
                            } else {
                                localStorage.clear()
                                window.location.href = "/login"
                            }
                        })
                    }
                    const retryOriginalRequest = new Promise(resolve => {
                        //console.log('in retryOriginalRequest')
                        this.addSubscriber(accessToken => {
                            //console.log('addSubscriber accessToken', accessToken)
                            // ** Make sure to assign accessToken according to your response.
                            // ** Check: https://pixinvent.ticksy.com/ticket/2413870
                            // ** Change Authorization header
                            originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                            resolve(axios(originalRequest))
                        })
                    })
                    return retryOriginalRequest

                } else if (response && response.status === 404) {
                    toast.error(
                        <ToastContent title="Error" color="danger" icon={<AlertTriangle size={16} />}
                            content="Application error, please contact IT department!" />,
                        { icon: false, hideProgressBar: true, autoClose: 2000 }
                    )
                } else if (response && response.status === 500) {
                    toast.error(
                        <ToastContent title="Error" color="danger" icon={<AlertTriangle size={16} />}
                            content="Server error, please contact IT department!" />,
                        { icon: false, hideProgressBar: true, autoClose: 2000 }
                    )
                }
                return Promise.reject(error)
            }
        )
    }

    onAccessTokenFetched(accessToken) {
        //console.log('in onAccessTokenFetched', accessToken);
        this.subscribers = this.subscribers.filter(callback => callback(accessToken))
    }

    addSubscriber(callback) {
        this.subscribers.push(callback)
    }

    getToken() {
        return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
    }

    getRefreshToken() {
        return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
    }

    setToken(value) {
        localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    }

    setUserData(value) {
        localStorage.setItem(this.jwtConfig.storageUserDataKeyName, value)
    }

    setRefreshToken(value) {
        localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
    }

    setCookieValueName(value) {
        localStorage.setItem(this.jwtConfig.storageCookieValueKeyName, value)
    }

    getCookieValue() {
        return localStorage.getItem(this.jwtConfig.storageCookieValueKeyName)
    }

    login(...args) {
        return axios.post(this.jwtConfig.loginEndpoint, ...args)
    }

    register(...args) {
        return axios.post(this.jwtConfig.registerEndpoint, ...args)
    }

    refreshToken(args) {
        return  axios.post(this.jwtConfig.refreshEndpoint, args)
    }
}
