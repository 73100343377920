/**
 * @ROWEL
 *
 * Get Ability List from API Payload
 * */
export const getAbilityList = (data) => {
    console.log(data, "data==============>")

    /**
     * @ROWEL
     * Set Initial Abilities
     * **/
    const permissionList = [];
    // permissionList.push({
    //     action: 'manage',
    //     subject: 'dashboard'
    // })
    //
    // if (!data?.roleResponse?.isSuper) {
    //     if (data?.roleResponse?.viewPermissionList) {
    //         data?.roleResponse.viewPermissionList.map((permission) => {
    //             if (permission) {
    //                 permissionList.push({
    //                     action: 'manage',
    //                     subject: permission.value
    //                 })
    //             }
    //         })
    //     }
    // } else {
        /**
         * @ROWEL
         * Super Users with all access
         * */
        permissionList.push({
            action: "manage",
            subject: "all"
        })
    // }
    return permissionList;
}

/**
 * @ROWEL
 *
 * Get Button Action List from API Payload
 * */
export const getActionPermissionList = (data) => {

    const actionList = [];

    // if (!data?.roleResponse?.isSuper) {
    //     if (data?.roleResponse?.actionPermissionList) {
    //         data?.roleResponse.actionPermissionList.map((permission) => {
    //             if (permission) {
    //                 actionList.push(permission.value)
    //             }
    //         })
    //     }
    // } else {
        actionList.push('all')
    // }
    return actionList;
}


/**
 * @ROWEL
 *
 * Check Action can be performed
 * */
export const isButtonHasAccess = (btnAction) => {

    const actionList = JSON.parse(localStorage.getItem('action_permission'))

    if (btnAction) {
        if (actionList && actionList.length > 0) {
            if (actionList.length === 1 && actionList[0] && actionList[0] === "all") {
                return true
            } else if (actionList.includes(btnAction)) {
                return true
            }
        }
    }
    return false
}
