// ** Auth Endpoints
export default {
    loginEndpoint: `${process.env.REACT_APP_API_BASE_URL}authenticate`,
    registerEndpoint: '/jwt/register',
    refreshEndpoint: `${process.env.REACT_APP_API_BASE_URL}authenticate/refresh`,
    logoutEndpoint: '/jwt/logout',

    // ** This will be prefixed in authorization header with token
    // ? e.g. Authorization: Bearer <token>
    tokenType: 'Bearer',

    // ** Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: 'accessToken',
    storageRefreshTokenKeyName: 'refreshToken',
    storageCookieValueKeyName: 'cookieValue',
    storageUserDataKeyName: 'userData'
}
